<template>
  <div class="sub-library-list">
    <el-row :gutter="30">
      <el-col class="library-item-col" :span="item.span || 4" v-for="item in navList" @click="onClickRouter(item)">
        <div>
          <div style="" class="item-box">
            <img :src="item.img" />
            <div class="item-title">{{ item.title }}</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts" setup>
import { routeUtil } from '@/utils'
import { SubLibraryListInter } from '.'

defineProps<{
  navList: SubLibraryListInter[]
}>()
// 点击事件
const emits = defineEmits<{
  (e: 'clickStay', item: any): void
}>()

const onClickRouter = (item: any) => {
  // 停留，不跳转页面
  if (item.isStay) {
    emits('clickStay', item)
    return
  }

  // 跳转页面
  routeUtil.router.push({
    name: item.router,
    query: {
      ...item?.query,
    },
  })
}
</script>

<style scoped lang="scss">
.sub-library-list {
  .library-item-col {
    margin-bottom: 30px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    cursor: pointer;

    .item-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-shadow: 0 3px 6px 1px #ccc;
      border-radius: 18px;
      width: 192px;
      height: 222px;

      .item-title {
        text-align: center;
        margin-top: 10px;
      }

      img {
        height: auto;
      }
    }
  }
}
</style>
