<template>
  <NavList v-bind="$attrs" style="margin-top: 60px; margin-bottom: 50px" class="yy-container" :nav-list="computedList" />
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import NavList from '../../subLibrary-list/index.vue'
import { TagType } from '@/components/BaseLayout'

import { geneData, compoundData, proteinData, natureData } from './index'
const props = defineProps<{
  tag: TagType
}>()

const computedList = computed(() => {
  switch (props.tag) {
    case 'Gene':
      return geneData
    case 'Protein':
      return proteinData
    case 'Compound':
      return compoundData
    case 'Nature':
      return natureData
    default:
      return []
  }
})
</script>

<script lang="ts">
export default {
  name: 'SubLibraryListNav',
}
</script>

<style scoped lang="scss"></style>
